/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import _store from "./redux/store";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider, useSelector, useDispatch } from "react-redux";
import {
  Login,
  Layout,
  Users,
  UserDetail,
  Staff,
  Statistics,
  Request,
  Reason,
  SystemConfig
} from "./containers";
import * as actions from "./redux/actions/auth";
import "./App.scss";
const _history = createBrowserHistory();

const AuthorizedRoutes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state._auth);
  // const [ROLE] = React.useState(localStorage.getItem("role"));

  React.useEffect(() => {
    const tokenFromStorage = localStorage.getItem("access_token");
    if (tokenFromStorage) {
      if (window.location.pathname === "/auth") {
        history.replace("/");
      }
      dispatch(actions.initialLogin());
    }
    if (!tokenFromStorage) {
      history.replace("/auth");
    }
  }, []);
  return (
    <>
      <>
        {status ? (
          <Layout>
            <Switch>
              <Route path="/" exact>
                <Users />
              </Route>
              <Route path="/detail">
                <UserDetail />
              </Route>
              <Route path="/staffs">
                <Staff />
              </Route>
              <Route path="/requests">
                <Request />
              </Route>
              <Route path="/statistics">
                <Statistics />
              </Route>
              <Route path="/reason">
                <Reason />
              </Route>
              <Route path="/system-config">
                <SystemConfig />
              </Route>
            </Switch>
          </Layout>
        ) : (
          <Switch>
            <Route exact path="/auth">
              <Login />
            </Route>
          </Switch>
        )}
      </>
    </>
  );
};

function App() {
  return (
    <Provider store={_store}>
      <Router history={_history}>
        <AuthorizedRoutes />
      </Router>
    </Provider>
  );
}

export default App;
